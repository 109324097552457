@import '../../../styles/variables.scss';

.number-picker-wrapper {
    display: flex;

    input {
      border-radius: 6px 0 0 6px;
      border-left: 1px solid #cececd;
      border-top: 1px solid #cececd;
      border-bottom: 1px solid #cececd;
      text-align: center;
      font-size: 1.5em;
      font-family: $mainfont;
      padding: .5em 0;

      &:focus {
        outline: none;
      }
    }
  
  .rw-number-picker-spinners {
    display: flex;
    flex-direction: column;
    button {
      margin-left: 0;
      padding: .2em 1em;
      flex: 1;


      &:first-child {
        border-radius: 0 6px 0 0;
        color: white;
        background: $primary;
        border-right: 1px solid #cececd;
        border-top: 1px solid #cececd;

        &:hover {
          background-color: darken($primary, 10%);
        }
      }

      &:last-child {
        border-radius: 0 0 6px 0;
        color: white;
        background: $primary;
        border-right: 1px solid #cececd;
        border-bottom: 1px solid #cececd;
        &:hover {
          background-color: darken($primary, 10%);
        }

      }
    }
  }
}

.field-wrapper {
  display: flex;
  align-items: center;

  .number-picker-units {
    font-size: 1.3em;
    margin-left: .5em;
  }
}

.quesion-label {
  font-size: 1.2em;
  font-style: italic;
  margin: 2em 0;
  text-align: center;
}

.bp-divider {
  margin: 0 1em;
  font-size: 4em;
  font-weight: 100;
  font-family: $mainfont;
}

.number-picker-label-wrapper {
  label {
    text-align: center;
    font-size: 1.1em;
    margin: 1em auto;
    display: block;
  }
}

.row-wrapper {
  margin-bottom: 6em;
}