@import '../../../styles/variables.scss';

.overlay-panel {
  position: relative;
  width: 100vw;
  padding-bottom: 4em;

  .logo-wrapper {
    position: relative;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    mix-blend-mode: multiply;
    background-color: transparentize($darkgrey, .05)
  }

  .overlay-content {
    position: relative;
    max-width: 50em;
    margin: auto;
    color: white;
    font-size: 1.2em;
    line-height: 1.4em;

    h1.overlay-title {
      font-family: $titlefont;
      font-weight: 200;
      text-align: center;
      margin-bottom: 1em;
      font-size: 3em;
    }
  }
}