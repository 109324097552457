@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora:wght@400;700&display=swap);
.background-image-wrapper{display:flex;justify-content:center;align-items:center;height:100vh;width:100vw}.background-image-wrapper .background-image-container{position:fixed;top:0;bottom:0;left:0;right:0}.background-image-wrapper .background-image-container .background-image{height:100%;width:100%;object-fit:cover;object-position:center}.background-image-wrapper .background-image-container .background-image-overlay{position:absolute;top:0;bottom:0;left:0;right:0;background:rgba(95,95,95,0.95);mix-blend-mode:multiply}.background-image-wrapper .background-image-content{max-width:40em;z-index:1;color:white}

.logo-wrapper{text-align:center}.logo-wrapper .logo-image{height:15em}.login-form .field-wrapper{display:flex;flex-direction:column}.login-form .field-wrapper label{text-transform:uppercase;font-weight:400;margin:1.3em 0 .8em}.login-form .field-wrapper input{font-size:1.2em;border:1px solid #cececd;border-radius:6px;padding:.5rem;color:black;font-family:"Open Sans",sans-serif}.login-form .field-wrapper .form-error{color:#90F9DE;font-weight:600;font-size:.8em;margin:.5em 0}.login-form .login-actions-wrapper{margin-top:3em;display:flex;justify-content:flex-end}

.overlay-panel{position:relative;width:100vw;padding-bottom:4em}.overlay-panel .logo-wrapper{position:relative}.overlay-panel .overlay{position:absolute;top:0;left:0;right:0;bottom:0;width:100vw;mix-blend-mode:multiply;background-color:rgba(95,95,95,0.95)}.overlay-panel .overlay-content{position:relative;max-width:50em;margin:auto;color:white;font-size:1.2em;line-height:1.4em}.overlay-panel .overlay-content h1.overlay-title{font-family:"Lora",serif;font-weight:200;text-align:center;margin-bottom:1em;font-size:3em}

.welcome-actions-wrapper{display:flex;justify-content:center;margin:4em 0 0}.welcome-actions-wrapper button{margin:0}

.split-screen-wrapper{position:fixed;top:0;bottom:0;left:0;right:0;display:flex}.split-screen-wrapper .split-screen-content-wrapper{flex:1 1 55%;display:flex;justify-content:flex-start;align-items:center;flex-direction:column}.split-screen-wrapper .split-screen-content-wrapper h1.title{font-family:"Lora",serif;margin-top:2em;color:black;font-weight:200}.split-screen-wrapper .split-screen-content-wrapper .split-screen-content-inner{width:100%;flex:1 1;display:flex;flex-direction:column;padding:4em;align-items:center;position:relative}.split-screen-wrapper .split-screen-content-wrapper .split-screen-content-inner .split-screen-button{position:absolute;top:45%;right:0;transform:translateY(-50%) translateX(70%);background:transparent;border:none;z-index:2}.split-screen-wrapper .split-screen-content-wrapper .split-screen-content-inner .split-screen-button span{font-size:2em;color:white;margin-left:1em}.split-screen-wrapper .split-screen-content-wrapper .split-screen-content-inner .split-screen-button img:hover{-webkit-filter:saturate(2.2);filter:saturate(2.2)}.split-screen-wrapper .split-screen-background-wrapper{flex:1 1 45%;position:relative}.split-screen-wrapper .split-screen-background-wrapper img{width:100%;height:100%;object-fit:cover;object-position:center}.split-screen-wrapper .split-screen-background-wrapper .split-screen-overlay{position:absolute;top:0;bottom:0;left:0;right:0;background-color:rgba(95,95,95,0.95);mix-blend-mode:multiply}

.number-picker-wrapper{display:flex}.number-picker-wrapper input{border-radius:6px 0 0 6px;border-left:1px solid #cececd;border-top:1px solid #cececd;border-bottom:1px solid #cececd;text-align:center;font-size:1.5em;font-family:"Open Sans",sans-serif;padding:.5em 0}.number-picker-wrapper input:focus{outline:none}.number-picker-wrapper .rw-number-picker-spinners{display:flex;flex-direction:column}.number-picker-wrapper .rw-number-picker-spinners button{margin-left:0;padding:.2em 1em;flex:1 1}.number-picker-wrapper .rw-number-picker-spinners button:first-child{border-radius:0 6px 0 0;color:white;background:#90F9DE;border-right:1px solid #cececd;border-top:1px solid #cececd}.number-picker-wrapper .rw-number-picker-spinners button:first-child:hover{background-color:#60f6d0}.number-picker-wrapper .rw-number-picker-spinners button:last-child{border-radius:0 0 6px 0;color:white;background:#90F9DE;border-right:1px solid #cececd;border-bottom:1px solid #cececd}.number-picker-wrapper .rw-number-picker-spinners button:last-child:hover{background-color:#60f6d0}.field-wrapper{display:flex;align-items:center}.field-wrapper .number-picker-units{font-size:1.3em;margin-left:.5em}.quesion-label{font-size:1.2em;font-style:italic;margin:2em 0;text-align:center}.bp-divider{margin:0 1em;font-size:4em;font-weight:100;font-family:"Open Sans",sans-serif}.number-picker-label-wrapper label{text-align:center;font-size:1.1em;margin:1em auto;display:block}.row-wrapper{margin-bottom:6em}

.rating-dot{color:#90F9DE}.field-wrapper{justify-content:center}

.textarea-row-wrapper{max-width:50em;width:100%}.journal-textarea{font-size:1.8em;border:1px solid #cececd;border-radius:6px;padding:1rem;color:black;font-family:"Open Sans",sans-serif;height:50vh;margin:auto;width:100%}.instructions{font-size:1.3em}


body{font-family:'Open Sans', sans-serif}button{text-decoration:none;font-size:15px;padding:10px 40px;border:1px solid #90f9de;box-sizing:border-box;font-family:Open Sans,sans-serif;border-radius:4px;display:flex;justify-content:space-around;align-items:center;margin-left:1em}button.primary{background-color:#90F9DE;color:#000}button.primary:hover{background-color:#60f6d0}button.secondary{background-color:white;color:#000}button.secondary:hover{background-color:#e6e6e6}

