@import '../../../styles/variables';

.logo-wrapper {
  text-align: center;

  .logo-image {
    height: 15em;
  }
}

.login-form {
  .field-wrapper {
    display: flex;
    flex-direction: column;

    label {
      text-transform: uppercase;
      font-weight: 400;
      margin: 1.3em 0 .8em;
    }

    input {
      font-size: 1.2em;
      border: 1px solid #cececd;
      border-radius: 6px;
      padding: .5rem;
      color: black;
      font-family: $mainfont;
    }

    .form-error {
      color: $primary;
      font-weight: 600;
      font-size: .8em;
      margin: .5em 0;
    }
  }

  .login-actions-wrapper {
    margin-top: 3em;
    display: flex;
    justify-content: flex-end;
  }
}