@import '../../../styles/variables';

.background-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  
  .background-image-container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .background-image {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }

    .background-image-overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: transparentize($darkgrey, .05);
      mix-blend-mode: multiply;
    }
  }
  .background-image-content {
    max-width: 40em;
    z-index: 1;
    color: white;
  }
}