@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;700&display=swap');
@import './variables.scss';


body {
  font-family: 'Open Sans', sans-serif;
}

button {
  text-decoration: none;
  font-size: 15px;
  padding: 10px 40px;
  border: 1px solid #90f9de;
  box-sizing: border-box;
  font-family: Open Sans,sans-serif;
  border-radius: 4px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 1em;

  &.primary {
    background-color: $primary;
    color: #000;

    &:hover {
      background-color: darken($primary, 10%);
    }

  }

  &.secondary {
    background-color: white;
    color: #000;

    &:hover {
      background-color: darken(white, 10%);
    }
  }
}