@import '../../../styles/variables.scss';

.split-screen-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;

  .split-screen-content-wrapper {
    flex: 1 1 55%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    h1.title {
      font-family: $titlefont;
      margin-top: 2em;
      color: black;
      font-weight: 200;
    }

    .split-screen-content-inner {
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 4em;
      align-items: center;
      position: relative;

      .split-screen-button {
        position: absolute;
        top: 45%;
        right: 0;
        transform: translateY(-50%) translateX(70%);
        background: transparent;
        border: none;
        z-index: 2;

        span {
          font-size: 2em;
          color: white;
          margin-left: 1em;
        }

        img:hover {
          filter: saturate(2.2);
        }
      }
    }
  }

  .split-screen-background-wrapper {
    flex: 1 1 45%;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    .split-screen-overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: transparentize($darkgrey, .05);
      mix-blend-mode: multiply;
    }
  }
}