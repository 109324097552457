@import '../../../styles/variables.scss';

.textarea-row-wrapper {
  max-width: 50em;
  width: 100%;
}

.journal-textarea {
  font-size: 1.8em;
  border: 1px solid #cececd;
  border-radius: 6px;
  padding: 1rem;
  color: black;
  font-family: $mainfont;
  height: 50vh;
  margin: auto;
  width: 100%;
}

.instructions {
  font-size: 1.3em;
}